import E404 from "../../components/error-pages/E404"
import Header from "../../components/error-pages/errorheader"
import Layout from "../../components/layout"
import React from "react"

const E404Page = props => {
  const staticContent = props.pageContext.staticContent;
  const staticImages = props.pageContext.staticImages;
  
  return (
    <Layout>
      <Header />
      <E404 staticContent={staticContent} staticImages={staticImages}/>
    </Layout>
  )
}

export default E404Page;